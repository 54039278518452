// Body
$body-bg: #DFDFED;
$input-bg: #FFFFFF;

$primary: #1f54a2;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$offcanvas-horizontal-width:  50%;
$enable-negative-margins: true;


$nav-tabs-border-color:   #999;
$nav-tabs-link-active-border-color:   #999  #999 transparent;
$nav-tabs-link-hover-border-color:   #AAA  #AAA transparent;

