.container-fluid {
  max-width: 1800px;
}

.border-outline-form {
  border-color: #ced4da !important
}

.card-header.bg-primary{
  background-color: #6543BC!important;
}

.card-header.bg-black{
  background-color: #6543BC!important;
}

.card-header.bg-secondary{
  background-color: #AE98E5!important;
}

.card-header.bg-black.card-header-alt{
  background-color: #AE98E5!important;
}

h1{
  color: $primary;
  font-family: 'Roboto Condensed', sans-serif;
}

h2{
  color: $primary;
}

h3{
  color: $primary;
}


#sideBar + div {
  background-color:#DFDFED!important;

}

.sticky-sm-top {
  background-color:#DFDFED!important;

}

main .nav-tabs .nav-link.active {
  background: linear-gradient(180deg, lighten($body-bg, 5%), $body-bg);
  border-bottom: transparent;
}
